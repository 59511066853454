import request from '@/utils/request'

// 查询卡板列表数据
export function findCardTypes(params) {
  return request({
    url: `/card_types`,
    method: 'get',
    params: params
  })
}

// 查询单个卡板表单值
export function findCardTypeForm(id) {
  return request({
    url: `/card_types/${id}/form`,
    method: 'get'
  })
}

// 更新卡板数据
export function updateCardType(id, data) {
  return request({
    url: `/card_types/${id}`,
    method: 'put',
    data
  })
}

// 创建卡板数据
export function createCardType(data) {
  return request({
    url: `/card_types`,
    method: 'post',
    data
  })
}

// 更新卡片材质有效性
export function batchUpdateCardTypesEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/card_types/effective/batch`,
    method: 'patch',
    data
  })
}
